import {Component, Input, OnInit} from '@angular/core';
import {FraudPreventionSummary, ListSearchResponse} from "../model/model";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-fraud-prevention-list',
  templateUrl: './fraud-prevention-list.component.html',
  styleUrls: ['./fraud-prevention-list.component.scss']
})
export class FraudPreventionListComponent implements OnInit {

  ipColumns: string[] = ['ip', 'count'];
  fingerprintColumns: string[] = ['fingerprint', 'count'];
  displayedColumns = this.ipColumns;
  data: ListSearchResponse<FraudPreventionSummary>;

  @Input()
  eventID: number;
  param: string = "IP";


  constructor(public adminService: AdminService) {
  }

  ngOnInit(): void {
    this.loadData(this.param);
  }

  loadData(param: string) {
    this.adminService.getFraudPreventionSummary(this.eventID, param)
      .subscribe(value => {
        console.log('response: ' + value);
        this.data = value;
      })
  }

  // @ts-ignore
  toggle(val) {
    if (val == 'ip') {
      this.loadData('IP');
      this.displayedColumns = this.ipColumns
    } else {
      this.loadData('FINGERPRINT');
      this.displayedColumns = this.fingerprintColumns
    }
  }
}
