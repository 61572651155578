import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartType, GoogleChartComponent, Row} from "angular-google-charts";
import {Event} from "../model/model";

@Component({
  selector: 'app-checkin-chart',
  templateUrl: './checkin-chart.component.html',
  styleUrls: ['./checkin-chart.component.scss']
})
export class CheckinChartComponent implements OnInit {

  constructor() {
  }

  @Input()
  ticketTypeSold: Map<string, number>;

  @Input()
  checkinStats: Map<string, number>;

  @Input()
  event: Event;

  data: Row[] = [];

  chartType = ChartType.BarChart;
  chartOptions = {
    colors: ['#5CDB95', '#05386B'],
    legend: {position: 'none'},
    isStacked: 'percent',
    hAxis: {
      minValue: 0,
      ticks: [0, .2, , .4, .6, .8, 1]
    }

  };
  @ViewChild(GoogleChartComponent) checkinChart!: GoogleChartComponent;


  ngOnInit(): void {
    this.createChartData(this.event, this.ticketTypeSold, this.checkinStats);
  }


  createChartData(event: Event, ticketTypeSold: Map<string, number>, checkinStats: Map<string, number>) {
    let data: Row[] = [];


    this.event.ticketTypes.forEach(tt => {

      let sold = 0;
      let checkedIn = 0;
      if (ticketTypeSold.get(tt.id.toString())) {
        ticketTypeSold.get(tt.id.toString())!.valueOf();
      }

      if (checkinStats.get(tt.id.toString())) {
        checkedIn = checkinStats.get(tt.id.toString())!.valueOf();
        if (checkedIn < 0) {
          checkedIn = 0;
        }
      }

      let row = [tt.name, sold, checkedIn];
      data.push(row);
    });

    this.data = Object.assign([], data)
    // this.myData = data;
    this.checkinChart.data = this.data;
  }


}
