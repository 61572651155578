import {Component, OnInit} from '@angular/core';
import {StateService} from "../state.service";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(public stateService: StateService) {
  }

  headline: string;
  body: string;

  ngOnInit(): void {
  }

}
