<div>
  <mat-card>
    <mat-card-title>
      <mat-button-toggle-group name="salesBin" [(ngModel)]="chartBin" (change)="runSalesReport()">
        <mat-button-toggle value="H24">Last 24hrs</mat-button-toggle>
        <mat-button-toggle value="YESTERDAY">Yesterday</mat-button-toggle>
        <mat-button-toggle value="D7">Last 7 days</mat-button-toggle>
        <mat-button-toggle value="MTD">Month to Date</mat-button-toggle>
        <mat-button-toggle value="LASTMONTH">Last Month</mat-button-toggle>
        <mat-button-toggle value="D90">Last 90 days</mat-button-toggle>
        <mat-button-toggle value="YEAR">Last Year</mat-button-toggle>
        <mat-button-toggle value="ALL">All</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card-title>
    <google-chart #salesChart [type]="bar" [data]="this.chartData"
                  [options]="chartOptions"
                  class="salesChart"
                  *ngIf="this.chartData"></google-chart>
  </mat-card>
</div>


<app-order-list [orders]="orders"></app-order-list>
