import {Component, OnInit} from '@angular/core';
import {AdminService} from "./admin.service";
import {AdminUser, Language} from "./model/model";
import {hasPermission} from "./required-permission/required-permission.component";
import {StateService} from "./state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'liveticket-admin';
  menuOpen: boolean = false;
  adminUser: AdminUser;
  hasPermission = hasPermission;

  supportedLangs: Language[];

  constructor(public adminService: AdminService, public stateService: StateService) {
  }

  ngOnInit() {
    this.adminService.getUser().subscribe(value => {
      this.adminUser = value;
      this.stateService.setAdminUser(value);
      this.checkCompleted()
    });

    this.adminService.getSupportedLanguage().subscribe(value => {
      this.supportedLangs = value;
      this.checkCompleted();


    });
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  showDate(): string {

    const formatter = new Intl.RelativeTimeFormat(undefined, {
      numeric: 'auto'
    });

    return formatter.format(3, 'week');
  }

  checkCompleted() {
    if (this.adminUser && this.supportedLangs) {
      this.menuOpen = true;
    }
  }
}
