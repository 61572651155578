<h1>Create Notification</h1>
<div>
  <form [formGroup]="notificationForm" class="notificationForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" placeholder="Enter message" rows="12" class="message"></textarea>
    </mat-form-field>
    <div>
      <button mat-raised-button color="primary" type="submit">
        Submit
      </button>
    </div>
  </form>
</div>
