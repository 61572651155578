import {Injectable} from '@angular/core';
import {AdminUser} from "./model/model";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() {
  }

  adminUser: AdminUser;


  setAdminUser(adminUser: AdminUser) {
    this.adminUser = adminUser;
  }

  getAdminUser(): AdminUser {
    return this.adminUser;
  }
}
