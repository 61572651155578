<div>
<h1>
  Translations - {{this.lang}}
</h1>

<!--  <button (click)="translateAll()">Translate All</button>-->

  <div>
    <mat-tab-group mat-align-tabs="start" *ngIf="lang" animationDuration="0ms">
      <mat-tab label="Common">
        <ng-template matTabContent>
          <app-translation [context]="'common'" [language]="this.lang">
            Common text used all over
          </app-translation>
        </ng-template>
      </mat-tab>

      <mat-tab label="Shop">
        <ng-template matTabContent>
          <app-translation [context]="'shop'" [language]="this.lang">
            This is the shop where end users will actually buy tickets.
            <br><br>
            You can view a sample shop here to view any changes you make
            <a href="https://shop.queue.lol/1jgcdv8k2yo"  target="_blank">shop.queue.lol</a>
            (you an purchase tickets also, everthing is in
            test mode, <b>you will not be charged</b>)
            <br><br>

            If you see something in brackets like this <b>{{'{{' + 'something' + '}}'}}</b> it is a variable that
            will be filled in later, so please preserve it in the overall translation.
          </app-translation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Email">
        <ng-template matTabContent>
          <app-translation [context]="'email'" [language]="this.lang">
            Email sent to the user.  There is no easy way to show this.

            <br><br>

            If you see something in brackets like this <b>{{'{{' + 'something' + '}}'}}</b> it is a variable that
            will be filled in later, so please preserve it in the overall translation.
          </app-translation>
        </ng-template>
      </mat-tab>
      <mat-tab label="www">
        <ng-template matTabContent>
          <app-translation [context]="'www'" [language]="this.lang">
            This is for the main marketing site where people land from Google etc. When you make changes here
            you can find them directly at <a href="https://queue.lol"  target="_blank">queue.lol</a>.
            <br><br>

            If you see something in brackets like this <b>{{'{{' + 'something' + '}}'}}</b> it is a variable that
            will be filled in later, so please preserve it in the overall translation.
          </app-translation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Dashboard">
        <ng-template matTabContent>
          <app-translation [context]="'dashboard'" [language]="this.lang">
            This is where organizers manage their events.  It is the biggest part of the system<br><br>
            You can logon here to check translations
            <a href="https://dashboard.queue.lol/auth/login" target="_blank">dashboard.queue.lol</a>
            <br><br>
            If you see something in brackets like this <b>{{'{{' + 'something' + '}}'}}</b> it is a variable that
            will be filled in later, so please preserve it in the overall translation.
          </app-translation>
        </ng-template>
      </mat-tab>
      <mat-tab label="Auth/Login">
        <ng-template matTabContent>
          <app-translation [context]="'auth'" [language]="this.lang">
            This is where organizers logon to manage their events.<br><br>
            You can see it here (note: logon with username and password, and recovery screens are disabled for now)
            <a href="https://dashboard.queue.lol/auth/login" target="_blank">dashboard.queue.lol/auth</a>
            <br><br>
            If you see something in brackets like this <b>{{'{{' + 'something' + '}}'}}</b> it is a variable that
            will be filled in later, so please preserve it in the overall translation.
          </app-translation>
        </ng-template>
      </mat-tab>

      <mat-tab label="App">
        <ng-template matTabContent>
          <app-translation [context]="'app'" [language]="this.lang">
            The ticket scanning app
            <br><br>
            Unfortunately there is no easy way to see these change in realtime.
          </app-translation>
        </ng-template>
      </mat-tab>

      <mat-tab label="Other">
        <ng-template matTabContent>
          <app-translation [context]="'other'" [language]="this.lang">
            Some additional translations including confirmation emails, marketing content,
          </app-translation>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
