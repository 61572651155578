import {Component, Input, OnInit} from '@angular/core';
import {Order, Ticket} from "../model/model";

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  @Input()
  orders: Order[];

  displayedColumns: string[] = ['id', 'event', 'name', 'total', 'status', 'created'];

  constructor() { }

  ngOnInit(): void {
  }


}
