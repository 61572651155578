import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AdminService} from "../admin.service";
import {TranslationContext, TranslationItem} from "../model/model";
import {StateService} from "../state.service";
import {hasPermission} from "./../required-permission/required-permission.component";

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit, OnChanges {

  @Input()
  language: string;

  @Input()
  context: string;

  displayedColumns: string[] = ['desc', 'value'];

  translationContext: TranslationContext;
  originalTranslations: TranslationItem[];

  hasChange = false;
  hasPermission = hasPermission;

  constructor(public adminService: AdminService, public stateService: StateService) {
  }

  ngOnInit(): void {
    this.adminService.getTranslationEntries(this.context, this.language).subscribe(value => {
      this.translationContext = value;
    });
  }

  ngOnChanges() {
    this.adminService.getTranslationEntries(this.context, this.language).subscribe(value => {
      this.setTranslationContext(value);
    });
  }


  save() {
    this.adminService.saveTranslationEntries(this.context, this.language, this.translationContext)
      .subscribe(value => {
        this.setTranslationContext(value);
        this.hasChange = false;
      });

  }

  promoteFromStaging() {
    this.adminService.promoteFromStaging(this.context, this.language)
      .subscribe(value => {

      });

  }


  setTranslationContext(tc: TranslationContext) {
    this.translationContext = tc

    // clone - very hacky
    this.originalTranslations = JSON.parse(JSON.stringify(tc.translations));
  }

  checkChange(newEvent: any, oldStr: string) {

    console.log('new: ' + newEvent.target.value);
    console.log('old: ' + oldStr);
    this.hasChange = newEvent.target.value !== oldStr;

  }

  showActionBar():boolean
  {
    const adminUser = this.stateService.adminUser;
    const canWrite= hasPermission.call('',adminUser, 'translate:' + this.language,'write');
    const canPromote= hasPermission.call('',adminUser, 'translate:' + this.language,'promote');


    return (this.hasChange && canWrite) || canPromote;

  }
}

