<ng-container *ngIf="stateService.getAdminUser()">


  <ng-container *ngIf="stateService.getAdminUser().email=='a.potaris@gmail.com'">
    <h1>Love you bestie bear</h1>
    <br>
    <img src="/assets/images/akis.jpg" width="500" height="auto" class="image">
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='hans.sassen@gmail.com'">
    <h1>Malmo (=/- 10 years?) :D</h1>
    <br>
    <img src="/assets/images/hans.jpg" width="500" height="auto" class="image">
    <br>
    Danke je wel!
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='aosheatralee@gmail.com'">
    <h1>How did we survive this day?! Shula Rijxman haha</h1>
    <br>
    <img src="/assets/images/aidan.jpg" width="500" height="auto" class="image">
    <br>
    Cry no more
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='mybigevent2022@gmail.com'">
    <h1>Thank you Chrissy!</h1>
    <br>
    <img src="/assets/images/chris.jpg" width="500" height="auto" class="image">

  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='nandorszabo@gmail.com'">
    <h1>Thank you :)</h1>
    <br>
    <img src="/assets/images/nandor.jpg" width="500" height="auto" class="image">

  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='alan.ward@gmail.com'">
    <h1>Thank you :)</h1>
    <br>
    <img src="/assets/images/andrea.jpg" width="500" height="auto" class="image">

  </ng-container>
  <ng-container *ngIf="stateService.getAdminUser().email=='drkldrmng@gmail.com'">
    <h1>Love you Sippycup!</h1>
    <br>
    <img src="/assets/images/sippy.jpg" width="500" height="auto" class="image">
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='r.stockmann@gmail.com'">
    <h1>Love you Ruby! Funniest Grindr photo ever :D</h1>
    <br>
    <img src="/assets/images/ruben.jpg" width="500" height="auto" class="image">
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='andrea.lamelas@gmail.com'">
    <h1>Dubai!! hahaha </h1>
    <br>
    <img src="/assets/images/andrea.jpg" width="500" height="auto" class="image">
    <br>

  </ng-container>


  <ng-container *ngIf="stateService.getAdminUser().email=='victorjulianromeo@gmail.com'">
    <h1>Thank you guapo! Running Wednesday?</h1>
    <br>
    <img src="/assets/images/victor.jpg" width="500" height="auto" class="image">
    <br>
    I gave you Spanish and Catalan, but maybe Borja is better for Catalan?
  </ng-container>

  <ng-container
    *ngIf="stateService.getAdminUser().email=='borjatarre@hotmail.com' || stateService.getAdminUser().email=='liveticketapp@outlook.com'">
    <h1>Thank you guapo! Good luck organizing! :)</h1>
    <br>
    <img src="/assets/images/borja.jpg" width="500" height="auto" class="image">
    <br>
  </ng-container>

  <ng-container *ngIf="stateService.getAdminUser().email=='alexrivard133@gmail.com'">
    <h1>Best cruise buddy, thank you!</h1>
    <br>
    <img src="/assets/images/alex.jpg" width="500" height="auto" class="image">
    <br>
  </ng-container>


  <h4>
    Just Common, Shop, WWW and Email :D
  </h4>
</ng-container>
