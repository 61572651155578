import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from "../model/model";

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  @Input()
  tickets: Ticket[];

  displayedColumns: string[] = ['id', 'customer', 'type', 'status', 'updatedAt'];


  constructor() {
  }

  ngOnInit(): void {
  }

}
