<div *ngIf="id">

  <div class="cardWrapper">
    <mat-card >
      <mat-card-title>{{organization.name}}</mat-card-title>
      <mat-card-subtitle>{{organization.email}}</mat-card-subtitle>
    </mat-card>
  </div>

  <app-event-list [organization]="this.id"></app-event-list>
  <app-user-list [organization]="this.id"></app-user-list>

</div>
