import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AdminUser, CreateNotificationRQ,
  Event, EventContact, EventMeta,
  EventStats, FraudPreventionSummary,
  Language, ListSearchResponse, Order,
  Organization,
  Storefront,
  Ticket,
  TranslationContext,
  User
} from "./model/model";


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  withCredentials: true
};
const httpOptionsNoJson = {

  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {

  }


  login(id: string): Observable<any> {
    const url = environment.apiBase + '/admin/login';
    const response = this.http.post(url, id, httpOptionsNoJson);
    return response;
  }

  public getEvents(org: number | null, status: number | null): Observable<Event[]> {

    let url = environment.apiBase + '/admin/events/?';
    if (org) {
      url = url + '&org=' + org;
    }
    if (status) {
      url = url + '&status=' + status;
    }
    return this.http.get<Event[]>(url, httpOptions);
  }

  public getEvent(id: number): Observable<Event> {
    return this.http.get<Event>(environment.apiBase + '/admin/event/' + id, httpOptions);
  }

  public getEventStorefronts(id: number): Observable<Storefront[]> {
    return this.http.get<Storefront[]>(environment.apiBase + '/admin/event/' + id + '/storefronts', httpOptions);
  }

  public getEventChartData(eid: number, bin: string): Observable<any[][]> {
    return this.http.get<any[][]>(environment.apiBase + '/admin/order/query?event=' + eid + '&bin=' + bin, httpOptions);
  }

  public getChartData(bin: string): Observable<any[][]> {
    return this.http.get<any[][]>(environment.apiBase + '/admin/order/query?bin=' + bin, httpOptions);
  }

  public getEventStats(id: number): Observable<EventStats> {
    return this.http.get<EventStats>(environment.apiBase + '/admin/event/' + id + '/stats', httpOptions);
  }

  public getEventMeta(id: number): Observable<EventMeta> {
    return this.http.get<EventMeta>(environment.apiBase + '/admin/event/' + id + '/meta', httpOptions);
  }

  public setPlan(id: number, plan: string): Observable<any> {
    return this.http.get<any>(environment.apiBase + '/admin/event/' + id + '/plan/' + plan, httpOptions);
  }

  public getPlans(): Observable<string[]> {
    return this.http.get<string[]>(environment.apiBase + '/admin/plans', httpOptions);
  }

  public getUsers(org: number | null): Observable<User[]> {
    let url = environment.apiBase + '/admin/users/';
    if (org) {
      url = url + '?org=' + org;
    }
    return this.http.get<User[]>(url, httpOptions);
  }

  public getLastTickets(id: number): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(environment.apiBase + '/admin/event/' + id + '/lastTickets', httpOptions);
  }

  public recalculate(id: number): Observable<Event> {
    return this.http.get<Event>(environment.apiBase + '/admin/event/' + id + '/recalculate', httpOptions);
  }

  public eventContacts(id: number): Observable<EventContact[]> {
    return this.http.get<EventContact[]>(environment.apiBase + '/admin/event/' + id + '/contacts', httpOptions);
  }

  public lastOrders(): Observable<ListSearchResponse<Order>> {
    let url = environment.apiBase + '/admin/lastOrders';
    return this.http.get<ListSearchResponse<Order>>(url, httpOptions);
  }


  public reindexOrders(id: number): Observable<any> {
    return this.http.get<any>(environment.apiBase + '/admin/event/' + id + '/reindex', httpOptions);
  }


  public reindexTickets(id: number): Observable<any> {
    return this.http.get<any>(environment.apiBase + '/admin/event/' + id + '/reindexTickets', httpOptions);
  }

  public getOrganizations(): Observable<Organization[]> {
    let url = environment.apiBase + '/admin/organizations/';

    return this.http.get<Organization[]>(url, httpOptions);
  }

  public getFraudPreventionSummary(eid: number, param: string): Observable<ListSearchResponse<FraudPreventionSummary>> {
    let url = environment.apiBase + '/admin/event/' + eid + '/fraud/' + param;

    return this.http.get<ListSearchResponse<FraudPreventionSummary>>(url, httpOptions);
  }

  public getOrganization(id: number): Observable<Organization> {
    let url = environment.apiBase + '/admin/organization?id=' + id;

    return this.http.get<Organization>(url, httpOptions);
  }

  public getTranslationEntries(context: string, lang: string): Observable<TranslationContext> {
    let url = environment.apiBase + '/admin/translation/' + context + '/' + lang;

    return this.http.get<TranslationContext>(url, httpOptions);
  }

  public saveTranslationEntries(context: string, lang: string, tc: TranslationContext): Observable<TranslationContext> {
    let url = environment.apiBase + '/admin/translation/' + context + '/' + lang;

    return this.http.post<TranslationContext>(url, tc, httpOptions);
  }

  public promoteFromStaging(context: string, lang: string): Observable<TranslationContext> {
    let url = environment.apiBase + '/admin/translation/promoteFromStaging/' + context + '/' + lang;

    return this.http.get<TranslationContext>(url, httpOptions);
  }

  public createNotification(cnrq: CreateNotificationRQ): Observable<any> {
    let url = environment.apiBase + '/admin/notification';

    return this.http.post<any>(url, cnrq, httpOptions);
  }

  public getUser(): Observable<AdminUser> {
    let url = environment.apiBase + '/admin/user';

    return this.http.get<AdminUser>(url, httpOptions);
  }

  public translateAll(): Observable<string> {
    let url = environment.apiBase + '/admin/translateAll';

    return this.http.get<string>(url, httpOptions);
  }

  public getSupportedLanguage(): Observable<Language[]> {
    let url = environment.apiBase + '/admin/developmentLanguages';

    return this.http.get<Language[]>(url, httpOptions);
  }

}
