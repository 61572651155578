import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventListComponent} from "./event-list/event-list.component";
import {EventDetailComponent} from "./event-detail/event-detail.component";
import {UserListComponent} from "./user-list/user-list.component";
import {OrganizationListComponent} from "./organization-list/organization-list.component";
import {OrganizationDetailsComponent} from "./organization-details/organization-details.component";
import {AuthComponent} from "./auth/auth.component";
import {TranslationComponent} from "./translation/translation.component";
import {TranslationDashboardComponent} from "./translation-dashboard/translation-dashboard.component";
import {WelcomeComponent} from "./welcome/welcome.component";
import {OverviewComponent} from "./overview/overview.component";
import {NotificationsComponent} from "./notifications/notifications.component";

const routes: Routes = [
  {path: '', component: WelcomeComponent},
  {path: 'overview', component: OverviewComponent},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'auth', component: AuthComponent},
  {path: 'dashboard', component: EventListComponent},
  {path: 'events', component: EventListComponent},
  {path: 'event/:id', component: EventDetailComponent},
  {path: 'organization/:id', component: OrganizationDetailsComponent},
  {path: 'users', component: UserListComponent},
  {path: 'organizations', component: OrganizationListComponent},
  {path: 'notifications', component: NotificationsComponent},

  {path: 'translation/:lang', component: TranslationDashboardComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
