import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AdminService} from "../admin.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Order} from "../model/model";
import {ChartType} from "angular-google-charts";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  orders: Order[];
  chartBin: string = 'D7';
  chartData: any[][];
  lineChart = ChartType.LineChart;
  bar = ChartType.ColumnChart;

  chartOptions = {
    colors: ['#5CDB95', '#05386B'],
    legend: {position: 'none'},
    curveType: 'function',
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: {axis: 'tickets'},
      1: {axis: 'sales'}
    },
    animation: {
      duration: 1000,
      easing: 'out'
    },
    minValue: 0,
    vAxis: {viewWindow: {min: 0}},
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        tickets: {label: 'Value of sales'},
        sales: {label: 'Tickets sold'}
      }
    }
  };


  constructor(public route: ActivatedRoute, public adminService: AdminService, public db: AngularFireDatabase,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.runSalesReport();

    this.adminService.lastOrders().subscribe(value => {
      if (value) {
        this.orders = value.list;
      }
    })
  }

  runSalesReport() {
    this.adminService.getChartData(this.chartBin).subscribe(value1 => {
      this.chartData = value1;


      // this.interpolate(this.salesBin, value1);
      // console.log('sales data: ');
      // console.log(this.salesData);
    });
  }

}
