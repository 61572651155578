<ng-container *ngIf="this.data!=null">
  <h3>Fraud Prevention</h3>
  <mat-button-toggle-group (change)="toggle($event.value)" value="IP">
    <mat-button-toggle value="ip">IP</mat-button-toggle>
    <mat-button-toggle value="fingerprint">Fingerprint</mat-button-toggle>
  </mat-button-toggle-group>

  <table mat-table [dataSource]="this.data.list" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="fingerprint">
      <th mat-header-cell *matHeaderCellDef>Fingerprint</th>
      <td mat-cell *matCellDef="let element">{{element.fingerprint}}</td>
    </ng-container>
    <ng-container matColumnDef="ip">
      <th mat-header-cell *matHeaderCellDef>IP</th>
      <td mat-cell *matCellDef="let element">{{element.ip}}</td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef>Count</th>
      <td mat-cell *matCellDef="let element">{{element.count}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>
