<h1>Create Notification</h1>
<div>
  <form [formGroup]="notificationForm" class="notificationForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" placeholder="Enter message" rows="12" class="message"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Context</mat-label>
      <mat-select formControlName="context">
        <mat-option value="DASHBOARD">DASHBOARD</mat-option>
        <mat-option value="EVENT">EVENT</mat-option>
        <mat-option value="ORGANIZATION">ORGANIZATION</mat-option>
        <mat-option value="USER">USER</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option value="danger">danger</mat-option>
        <mat-option value="warning">info</mat-option>
        <!--        <mat-option value="info">info</mat-option>-->
        <mat-option value="success">success</mat-option>
        <mat-option value="dark">dark</mat-option>


      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>TTL (Seconds)</mat-label>
      <input matInput type="number" formControlName="ttl" placeholder="Seconds">
      <mat-hint #hint>86400 seconds in a day, default is 1 week</mat-hint>
    </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>Organizations</mat-label>
      <input matInput formControlName="organizations" placeholder="Organizations">
    </mat-form-field>

    <div>
      <button mat-raised-button color="primary" type="submit">
        Submit
      </button>
    </div>
  </form>
</div>
