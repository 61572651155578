import {Component, Input, OnInit} from '@angular/core';
import {User} from "../model/model";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  displayedColumns: string[] = ['user', 'org', 'lastSeen', 'role'];

  users: User[];

  @Input()
  organization: number;

  constructor(public adminService: AdminService) {

  }

  ngOnInit() {

    this.adminService.getUsers(this.organization).subscribe(value => {
      this.users = value;
    });
  }

}
