import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateNotificationRQ} from "../model/model";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notificationForm: FormGroup;

  constructor(public adminService: AdminService, public fb: FormBuilder) {
    this.notificationForm = this.fb.group({
      message: ['', Validators.required],
    });
  }


  ngOnInit(): void {

  }

  onSubmit() {
    let cnrq = new CreateNotificationRQ();

    cnrq.sendToAll = true;
    cnrq.markdown = this.notificationForm.get("message")?.getRawValue();
    this.adminService.createNotification(cnrq).subscribe(value => {
      console.log('Notification delivered');
    });
  }

}
