import {Component, OnInit} from '@angular/core';
import {Organization} from "../model/model";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'email'];

  orgs: Organization[];
  constructor(public adminService: AdminService) {

  }

  ngOnInit() {

    this.adminService.getOrganizations().subscribe(value => {
      this.orgs = value;
    });
  }

}
