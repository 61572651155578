export class StatusDefines {
    public static PENDING = 0;
    public static PAID = 1;
    public static PAYMENT_IN_PROCESS = 2;
    public static ACTIVE = 3;
    public static SUSPENDED = 4;
    public static DELETED = 5;
    public static RESERVED = 6;
    public static HELD = 7;
    public static REFUNDED = 8;
    public static CANCELLED = 9;
    public static EXPIRED = 10;
    public static REFUND_IN_PROGRESS = 11;
    public static INACTIVE = 12;

    public static USED = 20;
}
