import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateNotificationRQ} from "../model/model";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notificationForm: FormGroup;

  constructor(public adminService: AdminService, public fb: FormBuilder) {
    this.notificationForm = this.fb.group({
      message: ['', Validators.required],
      type: ['warning', Validators.required],
      context: ['DASHBOARD', Validators.required],
      ttl: ['604800', Validators.required],
      organizations: [],
    });
  }


  ngOnInit(): void {

  }

  onSubmit() {
    let cnrq = new CreateNotificationRQ();


    let orgStr = this.notificationForm.get("organizations");
    let orgs: number[] = [];


    if (orgStr?.getRawValue() != null && orgStr?.getRawValue().length > 0) {
      orgs = orgStr?.getRawValue().split(',').map((str: string) => Number(str));
    }

    if (orgs.length > 0) {
      cnrq.specificRecepients = orgs;
    } else {
      cnrq.sendToAll = true;
    }

    cnrq.markdown = this.notificationForm.get("message")?.getRawValue();
    cnrq.type = this.notificationForm.get("type")?.getRawValue();
    cnrq.context = this.notificationForm.get("context")?.getRawValue();
    cnrq.ttl = this.notificationForm.get("ttl")?.getRawValue();
    this.adminService.createNotification(cnrq).subscribe(value => {
      console.log('Notification delivered');
    });
  }

}
