<h3>Last Tickets</h3>

<table mat-table [dataSource]="this.tickets" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID.</th>
    <td mat-cell *matCellDef="let element">{{element.id}}</td>
  </ng-container>
  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef>Customer</th>
    <td mat-cell *matCellDef="let element">{{element.lookup['customer']}}</td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">{{element.ticketTypeName}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{element.status}}</td>
  </ng-container>
  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef>Updated</th>
    <td mat-cell *matCellDef="let element">{{element.updatedAt | date :'dd MMM, HH:mm'}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
