<div >
  <div class="header" fxLayout="row" fxLayoutAlign="start center">
    <span class="menuIcon"><mat-icon (click)="toggleMenu()">menu</mat-icon></span>
    <img class="ltLogo" src="https://www.liveticket.app/assets/liveticket/logo-light.svg">
    Admin
  </div>
  <mat-sidenav-container class="example-container">
    <mat-sidenav mode="side"  [(opened)]="menuOpen" fixedInViewport="true" position="start" [fixedTopGap]="84">
      <div class="menu"  *ngIf="this.adminUser">
        <ul>
          <li><a [routerLink]="['/overview']"
                 [ngClass]="{'disabled': !hasPermission.call('',this.adminUser, 'events','read')}">
            Overview</a>
          </li>
          <li><a [routerLink]="['/events']"
                 [ngClass]="{'disabled': !hasPermission.call('',this.adminUser, 'events','read')}">
            Events</a>
          </li>
          <li><a [routerLink]="['/users']"
                 [ngClass]="{'disabled': !hasPermission.call('',this.adminUser, 'users','read')}"
          >Users</a></li>
          <li><a [routerLink]="['/organizations']"
                 [ngClass]="{'disabled': !hasPermission.call('',this.adminUser, 'organizations','read')}"
          >Organizations</a></li>
          <li>Reports</li>
          <li><a [matMenuTriggerFor]="menu" *ngIf="supportedLangs">Translations</a>
            <mat-menu #menu="matMenu">

              <ng-container *ngFor="let lang of supportedLangs">
                <button mat-menu-item [routerLink]="['/translation', lang.code]"
                        [ngClass]="{'disabled': !hasPermission.call('',this.adminUser, 'translate:' + lang.code,'read')}"
                >{{lang.name}}</button>

              </ng-container>

            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-sidenav>

    <mat-sidenav-content autosize>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


</div>
