import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AdminService} from "./admin.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {EventListComponent} from './event-list/event-list.component';
import {EventDetailComponent} from './event-detail/event-detail.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {OrganizationListComponent} from './organization-list/organization-list.component';
import {UserListComponent} from './user-list/user-list.component';
import {OrganizationDetailsComponent} from './organization-details/organization-details.component';
import {AngularFireModule} from "@angular/fire/compat";
import {environment} from "../environments/environment";
import { AuthComponent } from './auth/auth.component';
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {GoogleChartsModule} from "angular-google-charts";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FormsModule} from "@angular/forms";
import { TranslationComponent } from './translation/translation.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { CheckinChartComponent } from './checkin-chart/checkin-chart.component';
import { TranslationDashboardComponent } from './translation-dashboard/translation-dashboard.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import { WelcomeComponent } from './welcome/welcome.component';
import { RequiredPermissionComponent } from './required-permission/required-permission.component';
import {MatChipsModule} from "@angular/material/chips";
import {HttpErrorsInterceptor} from "./interceptors/http-errors.interceptor";
import {MatSelectModule} from "@angular/material/select";
import { OverviewComponent } from './overview/overview.component';
import { OrderListComponent } from './order-list/order-list.component';
import { FraudPreventionListComponent } from './fraud-prevention-list/fraud-prevention-list.component';

@NgModule({
  declarations: [
    AppComponent,
    EventListComponent,
    EventDetailComponent,
    OrganizationListComponent,
    UserListComponent,
    OrganizationDetailsComponent,
    AuthComponent,
    TranslationComponent,
    TicketListComponent,
    CheckinChartComponent,
    TranslationDashboardComponent,
    WelcomeComponent,
    RequiredPermissionComponent,
    OverviewComponent,
    OrderListComponent,
    FraudPreventionListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatChipsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatCardModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    GoogleChartsModule,
    FormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule

  ],
  providers: [AdminService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
