
<table mat-table [dataSource]="this.orders" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let order">{{order.id}}</td>
  </ng-container>
  <ng-container matColumnDef="event">
    <th mat-header-cell *matHeaderCellDef>Event</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/event/' + element.event]">{{element.event}} </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.customer!=null">
        {{element.customer.name}}<br>{{element.customer.email}}
      </ng-container>

    </td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total</th>
    <td mat-cell *matCellDef="let element">{{element.pricing.total|currency:element.pricing.currency}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{element.status}}</td>
  </ng-container>
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let element">{{element.createdAt | date :'dd MMM, HH:mm'}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
