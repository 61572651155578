import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AdminService} from "../admin.service";

@Component({
  selector: 'app-translation-dashboard',
  templateUrl: './translation-dashboard.component.html',
  styleUrls: ['./translation-dashboard.component.scss']
})
export class TranslationDashboardComponent implements OnInit {

  lang: string;

  constructor(private route: ActivatedRoute, public adminService: AdminService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.lang = paramMap.get('lang')!;
      console.log('route has changed!' + this.lang);
    });
  }

  translateAll()
  {
    this.adminService.translateAll()
      .subscribe(value => {
        
      });
  }

}
