import {Component, OnInit} from '@angular/core';
import {AdminService} from "../admin.service";
import {Router} from "@angular/router";
import firebase from "firebase/compat/app";
import {AngularFireAuth} from '@angular/fire/compat/auth';
import AuthProvider = firebase.auth.AuthProvider;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(public adminService: AdminService, public auth: AngularFireAuth,
              public router: Router) {
  }

  ngOnInit(): void {
  }


  googleAuth() {

    this.signinWithAuthProvider(new firebase.auth.GoogleAuthProvider());
  }

  microsoftAuth() {
    this.signinWithAuthProvider(new firebase.auth.OAuthProvider('microsoft.com'));
  }

  signinWithAuthProvider(authProvider: AuthProvider) {

    this.auth.signInWithPopup(authProvider).then(value => {
      // call endpoint

      // what happened here?
      // @ts-ignore
      value!.user.getIdToken(true).then(idToken => {
        this.adminService.login(idToken).subscribe(value1 => {
          this.router.navigate(['welcome']);
        });
      }).catch((error) => {
      });
    }).catch((error) => {
    });
  }
}
