<h1>Organizations</h1>

<table mat-table [dataSource]="this.orgs" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID</th>
    <td mat-cell *matCellDef="let org">
      <a [routerLink]="['/organization/' + org.id]">{{org.id}} </a>
    </td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name</th>
    <td mat-cell *matCellDef="let org">
      {{org.name}}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email</th>
    <td mat-cell *matCellDef="let org"> {{org.email}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
