<div class="container" *ngIf="translationContext && translationContext.translations">

  <p class="description">
    <ng-content></ng-content>
  </p>
  <app-required-permission [resource]="'translate:' + language" [right]="'read'"
                           [user]="this.stateService.getAdminUser()">
    <table mat-table [dataSource]="translationContext.translations" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->


      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef> Description</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.defaultValue}}</div>
            <div class="muted">{{element.key}}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Value</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="textAreaContainer">
          <textarea [(ngModel)]="translationContext.translations[i].value"
                    (change)="this.checkChange($event, this.originalTranslations[i].value)"
                    (keyup)="this.checkChange($event, this.originalTranslations[i].value)"
                    [disabled]="!hasPermission.call('',stateService.getAdminUser(), 'translate:' + this.language,'write')">

          </textarea>
          </div>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-required-permission>
</div>
<div class="sticky" [ngClass]="{'show': this.showActionBar()}">
  <div fxLayout="row" fxLayoutAlign="start center" class="footer">
    <app-required-permission [resource]="'translate:' + language" [right]="'write'"
                             [user]="this.stateService.getAdminUser()">
      <button mat-flat-button (click)="save()" class="saveBtn">Save</button>
    </app-required-permission>
    <app-required-permission [resource]="'translate:' + language" [right]="'promote'"
                             [user]="this.stateService.getAdminUser()">
      <button mat-flat-button (click)="promoteFromStaging()" class="saveBtn">Promote from staging</button>
    </app-required-permission>

    <p>{{language}}</p>
  </div>


</div>
