import {Component, Input, OnInit} from '@angular/core';
import {Event} from "../model/model";
import {AdminService} from "../admin.service";
import {environment} from "../../environments/environment";
import {StatusDefines} from "../model/defines";

// @ts-ignore
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'location', 'startDate'];

  events: Event[];
  STATUS_DEFINES = StatusDefines;
  @Input()
  organization: number;

  constructor(public adminService: AdminService) {

  }

  ngOnInit() {

    this.load(StatusDefines.ACTIVE);
  }

  load(status: number | null) {
    this.adminService.getEvents(this.organization, status).subscribe((value: Event[]) => {
      this.events = value;
    });
  }



}
