import {Component, Input, OnInit} from '@angular/core';
import {AdminUser, Policy} from "../model/model";

@Component({
  selector: 'app-required-permission',
  templateUrl: './required-permission.component.html',
  styleUrls: ['./required-permission.component.scss']
})
export class RequiredPermissionComponent implements OnInit {

  constructor() {
  }

  @Input()
  resource: string;

  @Input()
  right: string;

  @Input()
  user: AdminUser;

  ngOnInit(): void {
  }

  hasRequiredPermission(): boolean {
    return hasPermission(this.user, this.resource, this.right);
  }


}

export function hasPermission(user: AdminUser, resource: string, right: string): boolean {

  let result = false;

  if (user.policies != null) {
    for (let policy of user.policies) {
      if (checkPolicy(resource, right, policy)) {
        result = true;
        break;
      }
    }
  }
  return result;
}


export function checkPolicy(resource: string, right: string, userPolicy: Policy): boolean {

  let result = false;
  if (userPolicy.resource == resource || resource.startsWith(userPolicy.resource)) {
    if (userPolicy.rights.includes(right)) {
      result = true;
    }
  }

  return result;
}
