import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AdminService} from "../admin.service";
import {Organization} from "../model/model";

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {

  constructor(public route: ActivatedRoute, public adminService: AdminService) {
  }

  id: number;
  organization: Organization;

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id')!;

    this.adminService.getOrganization(this.id).subscribe(value => {
      this.organization = value;
    });
  }

}
