<div>

<h1 class="sticky">Users</h1>

<table mat-table [dataSource]="this.users" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef> User</th>
    <td mat-cell *matCellDef="let user">
      <div fxlayout="row" fxLayoutAlign="start center">
        <div>
          <img src="{{user.photoUrl}}" class="avatar">
        </div>
        <div fxlayout="column">
          <div>{{user.name}}</div>
          <div>{{user.email}}</div>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="org">
    <th mat-header-cell *matHeaderCellDef> Org</th>
    <td mat-cell *matCellDef="let user">
      <a [routerLink]="['/organization/' + user.organization]">{{user.organization}} </a>
    </td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="lastSeen">
    <th mat-header-cell *matHeaderCellDef> Last Seen</th>
    <td mat-cell *matCellDef="let user">
      {{user.lastSeen * 1000 | date:'dd MMM yy HH:mm'}}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role</th>
    <td mat-cell *matCellDef="let user"> {{user.role}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>
