import {Moment} from 'moment';

export class Event {
  id: number;
  name: string;
  location: Location;
  timeZone: string;
  description: string;
  startDateTime: Moment;
  endDateTime: Moment;
  salesStartDateTime: Moment;
  eventCurrency: string;
  ticketTypes: TicketType[];
  eventTemplate: EventTemplate;
  mainImage: string;
  hasTicketTemplate: boolean;
  lookup: Map<string, string>;
  organization: number;
}

export class EventContact {
  contact: string;
  source: string;
}

export class Storefront {
  id: number;
  template: EventTemplate;
  event: number;
}

export class TimeZone {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

export class Location {
  place: Place;
  venue: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
}

export class Place {


  public formattedAddress: string;
  public adrAddress: string;

  public name: string;
  public placeId: string;
  public id: string;
  public utcOffset: number;
  public url: string;

  public lat: number;
  public lng: number;


}


export class TicketPrice {
  currency: string;
  amount: number;
}

export class TicketType {
  id: number;
  name: string;
  description: string;
  quantity: number;

  price: number;
  currency: string;
  startSaleDateTime: Date;
  endSaleDateTime: Date;
  minQuantity: number;
  maxQuantity: number;
  restrictionType: number;
  refundPolicy: number;
  absorbPayment: boolean;
  absorbService: boolean;
  indicativeAvailability: number;
}


export class EventTemplate {
  template: string;
  parameters: Map<string, string>;
  timestamp: number;
  templateImage: string;

}


export class OrderItem {
  ticketType: number;
  quantity: number;
  price: number;
  currency: string;
  ticketTypeName: string;

}


export class Order {
  constructor(public id: number, public lookupToken: string, public event: number, public createdAt: Date,
              public expiresAt: Date, public status: number,
              public orderItems: OrderItem[], public pricing: OrderPricing,
              public tickets: string[], public customer: Customer, public ttl: number) {
  }
}

export class OrderPricing {
  constructor(public currency: string, public orderItemTotal: number,
              public paymentCostTotal: number, public serviceCostTotal: number, public total: number) {
  }
}

export class Customer {
  constructor(public name: string, public email: string) {
  }
}


export class Country {
  constructor(public code: string, public name: string) {
  }
}

export class Language {
  constructor(public code: string, public name: string) {
  }
}

export class Organization {
  id: number;
  name: string;
  email: string;
}

export class EventStats {
  eventID: number;
  ticketsSold: number;
  ticketsRemaining: number;
  totalQuantity: number;
  totalOrders: number;
  totalRevenue: number;
  ticketTypeQty: Map<string, number>;
  ticketTypeSales = {};
}

export class EventMeta {
  plan: string;
}

export class User {
  userName: string;
  photoUrl: string;
  name: string;
  email: string;
  organization: number;
  lastSeen: number;
  role: string;
}


export class TranslationEntry {
  id: number;
  context: string;
  lang: string;
  key: string;
  value: string;
}

export class Ticket {
  id: number;
  order: number;
  orderStatus: number;
  ticketType: number;
  ticketTypeName: string;
  createdAt: Moment;
  updatedAt: Moment;
  status: string;
  lookupToken: string;
  lookup: Map<string, string>;

}

export class TranslationContext {
  context: string;
  language: string;
  translations: TranslationItem[];
}

export class TranslationItem {
  key: string;
  value: string;
  defaultValue: string;
}

export class AdminUser {
  email: string;
  name: string;
  policies: Policy[]
}

export class Policy {
  resource: string;
  rights: string[];
}

export class ListSearchResponse<T> {
  resultSize: number;
  cursor: string;
  list: T[];
  pageSize: number;
}

export class FraudPreventionSummary {
  ip: string;
  fingerprint: string;
  fingerprintDisplay: string;
  count: number;

}

export class CreateNotificationRQ {
  specificRecepients: number[];
  sendToAll: boolean;
  markdown: string;
  lang: string;
}
