export const environment = {
  production: false,
  apiBase: 'https://api.queue.lol',
  shopBase: 'https://shop.queue.lol',
  firebase: {
    apiKey: "AIzaSyAODgEZaNDXqKtMxdBHyrP8ucZA_JLJeZ0",
    authDomain: "test-queue-lol-server.firebaseapp.com",
    databaseURL: "https://test-queue-lol-server.firebaseio.com",
    projectId: "test-queue-lol-server",
    storageBucket: "test-queue-lol-server.appspot.com",
    messagingSenderId: "594509397166",
    appId: "1:594509397166:web:7f70eda3f504df3f15013f",
    measurementId: "G-TRY064X6EX"
  }
};
