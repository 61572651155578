<div class="cardWrapper" fxLayout="column">
  <div class="sticky"><h1>Events</h1></div>

  <div >
    <div class="filter">
    <mat-chip-list >
      <mat-chip (click)="load(STATUS_DEFINES.ACTIVE)">Active</mat-chip>
      <mat-chip (click)="load(null)">All</mat-chip>
    </mat-chip-list>
    </div>
    <table mat-table [dataSource]="this.events" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name.</th>
        <td mat-cell *matCellDef="let event"><a [routerLink]="['/event/' + event.id]">{{event.name}} </a></td>
      </ng-container>


      <!-- Weight Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef> Location</th>
        <td mat-cell *matCellDef="let event">
          <ng-container *ngIf="event.location">{{event?.location?.place?.formattedAddress}}</ng-container>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start</th>
        <td mat-cell *matCellDef="let event"> {{event.startDateTime|date:'dd MMM yy, HH:mm'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>

</div>
